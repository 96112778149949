@use '@angular/material' as mat;

@include mat.core();

$warn: mat.define-palette(mat.$red-palette);

.light-theme {
  --bg-color: #f5f5f5;
  $light-primary: mat.define-palette(mat.$blue-palette, 500);
  $light-accent: mat.define-palette(mat.$blue-palette, 900);

  $light-theme: mat.define-light-theme(
    (
      color: (
        primary: $light-primary,
        accent: $light-accent,
        warn: $warn,
      ),
    )
  );

  @include mat.all-component-themes($light-theme);

  --primary-color: #{mat.get-color-from-palette($light-primary)};
  --accent-color: #{mat.get-color-from-palette($light-accent)};
  --background-color: #f4f4f4;
}

.dark-theme {
  --bg-color: rgb(53, 54, 58);
  background-color: rgb(53, 54, 58);
  color: #f5f5f5;

  $dark-primary: mat.define-palette(mat.$grey-palette, 50);
  $dark-accent: mat.define-palette(mat.$grey-palette, 100);

  $dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $dark-primary,
        accent: $dark-accent,
        warn: $warn,
      ),
    )
  );

  @include mat.all-component-themes($dark-theme);

  // & hr{
  //     background: mat.get-color-from-palette($dark-primary);
  // }

  --primary-color: #{mat.get-color-from-palette($dark-primary)};
  --accent-color: #{mat.get-color-from-palette($dark-accent)};
  --background-color: rgb(53, 54, 58);
}

.mat-card {
  box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.6) !important;
}

.mat-form-field {
  font-size: 16px;
  & fa-icon {
    font-size: 21px;
    margin-right: 0.5rem;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}
